import React, { useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import Avatar from 'react-avatar'
import eventAgenda from '../../images/event/eventagenda.jpg'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import { COLORS } from '../../styles/theme'
import config from '../../config'
import { useDispatch } from 'react-redux'
import { refreshUser } from '../../ducks/auth'
import Amplitude from '../../utils/amplitudeHelper'

const StyledModal = styled(Modal)`
  text-align: center;
  color: ${COLORS.primary};

  .modal-dialog {
    min-width: 75%;
  }
  .modal-content {
    background-color: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 0;
    padding: 24px 36px;
    margin-bottom: 24px;

    .modal-header {
      color: #FFAD2C;
      border: none;
      text-align: left;
      padding-top: 0;
      padding-bottom: 0;
      .modal-title {
        font-size: 36px;
        font-weight: bold;
        font-style: italic;
      }
      button {
        font-size: 36px;
        opacity: 1;
        color: rgba(0, 0, 0, 0.55);
      }
    }
    .header-section {
      margin-top: 16px;
      #profile {
        .profile-image {
          border-radius: 50%;
          width: 98px;
          height: 98px;
          .avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
      .edit-card {
        text-align: left;
        background: white;
        border: 3px solid #FFAD2C;
        border-radius: 16px;
        padding: 16px;
        .form-label {
          width: 20%;
        }
        input {
          padding: 4px 16px;
          width: 80%;
        }
        .fileinput {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;
        }
        .fileinput + label {
          cursor: pointer;
        }
        .upload-label {
          align-self: center;
          margin: 0;
          background: ${COLORS.primary};
          color: #FFAD2C;
          border-radius: 32px;
          font-weight: 600;
          padding: 8px 26px;
        }
        .form-btn {
          button {
            margin-left: 16px;
            color: ${COLORS.primary};
            font-weight: 600;
            border-radius: 16px;
            padding: 2px 8px;
            border: 1px solid ${COLORS.primary};
          }
          .upload-btn:disabled {
            color: rgba(0, 0, 0, 0.5);
            border: 1px solid rgba(0, 0, 0, 0.5);
          }
        }
        .image-name {
          color: ${COLORS.primary};
          width: 265px;
          border: 1px solid #FFAD2C;
          border-radius: 12px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-style: italic;
          padding: 0px 12px;
          font-size: 12px;
          display:flex;
          align-items: center;
        }
      }
      button {
        border: none;
        background: transparent;
        color: ${COLORS.primary};
        display: block;
      }
      .navigation {
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        button {
          width: 150px;
          background: #002060;
          color: white;
          border: 2px solid #FFAD2C;
          border-radius: 32px;
          padding: 6px 16px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          h5 {
            margin: 0;
          }
          &:hover {
            background: #FFAD2C;
          }
        }
      }
    }
    .divider {
      background: linear-gradient(270deg, #FE1E9A, #254DDE);
      height: 4.5px;
      margin: 32px 0;
    }
  }
  .back-top {
    border: none;
    border-radius: 12px;
    background: #002060;
    position:absolute;
    bottom: 0;
    right: 0;
    svg {
      width:50;
      height:50;
    }
  }
  .modal-body {
    padding-bottom: 36px;
  }
  .agenda {
    button {
      margin: 16px;
      background: #002060;
      border: 2px solid #FFAD2C;
      font-weight: 500;
      border-radius: 32px;
      padding: 4px 12px;
      color: white;
      font-size: 24px;
    }
    .active {
      font-style: italic;
      color: #FFAD2C;
      text-decoration: underline;
    }
  }
`

export default function ProgrammeModal({ showModal, hideSchedule, currentUser }) {
  const speakerSec = useRef()
  const aboutSec = useRef()
  const agendaSec = useRef()
  const startModal = useRef()

  const dispatch = useDispatch()
  const [ agenda, setAgenda ] = useState(true)
  const [ showEdit, setShowEdit ] = useState(false)
  const [ image, setImage ] = useState(null)
  const [ loading, setLoading ] = useState(false)

  const scrollTop = (ref) =>{
    ref.current.scrollIntoView({ behavior: 'smooth' })
  };
  
  return (
    <StyledModal
      show={showModal}
      onHide={hideSchedule}
      size="xl"
      aria-labelledby="menu-modal"
      centered
    >
      <Modal.Header closeButton>
        {/* <Modal.Title>AGENDA</Modal.Title> */}
        <span ref={startModal}></span>
      </Modal.Header>
      <Modal.Body>
        <div>
          <LazyLoadImage effect="black-and-white" src={eventAgenda} width="100%" alt="agenda" />
        </div>
        <button onClick={()=>scrollTop(startModal)} className="back-top">
          <svg width="45" height="45" viewBox="0 0 45 45">
            <g transform="translate(-1767 -3747)">
              <g transform="translate(120 -2428)">
                <path d="M22.175,9.358a1.43,1.43,0,0,0-1.43-1.43H5.063l5.813-5.42A1.43,1.43,0,1,0,8.923.421L.455,8.311a1.43,1.43,0,0,0,0,2.091L8.923,18.3A1.43,1.43,0,1,0,10.875,16.2L5.063,10.788H20.746A1.43,1.43,0,0,0,22.175,9.358Z" transform="translate(1678.681 6187) rotate(90)" fill="#ffad2c"/>
              </g>
            </g>
          </svg>
        </button>
      </Modal.Body>
    </StyledModal>
  )
}
