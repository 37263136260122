import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Redirect, Link } from 'react-router-dom'
import Axios from 'axios'
import { toast } from 'react-toastify'

import routes from '../../routes'
import config from '../../config'
import { COLORS } from '../../styles/theme'


const MobileForgotPassword = () => {
  const { isAuthenticated } = useSelector((state) => state.auth)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const handleInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    Axios.get(`${config.apiUrl}/api/v1/forget_password`, {
      params: {
        email,
      },
    })
      .then(function (response) {
        setLoading(false)
        if (response.data.exist) {
          toast(
            'We have sent you the password via email, please check your mailbox.'
          )
          setEmail('')
        } else {
          toast('This email does not seem to be registered to our system.')
        }
      })
      .catch(function (error) {
        setLoading(false)
        toast(error.response.data.message)
      })
  }

  if (isAuthenticated) {
    return <Redirect to={routes.lobby} />
  }

  return (
    <ContentDiv>
      <p className="title">Forgot Password</p>
      <p className="subtitle">Enter Email to request for Password</p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={handleInput}
          placeholder="Email"
        />
        <button
          type="submit"
          className="button"
          disabled={email.length < 4 || loading}
        >
          {loading ? 'Submitting' : 'Submit'}
        </button>
      </form>
      <Link to={routes.login} className="form-link">
        Cancel
      </Link>
    </ContentDiv>
  )
}

const ContentDiv = styled.div`
  background: #FFF;
  color: #1A1A1A;
  padding: 20px 10px;
  border-radius: 28px;
  margin: auto;
  margin-bottom: 32px;
  width: 85%;
  text-align: center;

  .title {
    font-size: 24px;
    /* font-weight: bold; */
    /* font-style: italic; */
    color: #1A1A1A;
    margin-bottom: 8px;
    text-align: center;
  }

  .subtitle {
    font-size: 14px;
    text-align: center;
    margin-bottom: 16px;
  }

  form {
    width: 95%;
    margin: auto;
  }

  input {
    width: 100%;
    background: ${COLORS.lightGray};
    border-radius: 25px;
    display: block;
    margin: 0px auto;
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 100px;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .button {
    width: 100%;
    background: #2e3188;
    font-weight: 500;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    color: #fff;
    font-style: italic;
  }

  .button:hover {
    cursor: pointer;
  }

  .button:disabled {
    opacity: 0.5;
  }

  .form-link {
    display: block;
    text-align: center;
    color: #1A1A1A;
    font-style: italic;
    font-size: 14px;
    margin-top: 14px;
  }
  @media screen and (min-width: 540px) {
    width: 60%;
    margin-bottom: 80px;
    form {
      width: 300px;
      margin: auto;
    }
  }
`

export default MobileForgotPassword
