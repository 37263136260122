import LogoL from './images/logo.png'
import Entrance from './images/backdrops/entrance.jpg'
import EntranceM from './images/backdrops/entranceP.jpg'
import LobbyL from './images/backdrops/lobby.jpg'
import CountdownL from './images/backdrops/countdownL.jpg'
import CountdownP from './images/backdrops/countdownP.jpg'
import BannerP from './images/backdrops/bannerP.png'
import BannerL from './images/backdrops/bannerL.png'
import Exhibition from './images/backdrops/exhibition-hall.jpg'
import Auditorium from './images/backdrops/auditorium.jpg'
import AuditoriumM from './images/backdrops/auditoriumP.jpg'
import NetworkingL from './images/backdrops/networking.jpg'
import LobbyMusic from './images/audio/lobby.mp3'
import routes from './routes'
import Gallerium from './images/backdrops/gallerium.jpg'

const dev = {
  apiUrl: 'http://localhost:3000',
}

const prod = {
  apiUrl: 'https://panashopmeeting2021.herokuapp.com',
}

const API_KEYS = {
  amplitude: '20badcb458e3db96503bbe7710340f36',
}
const eventDays = []

const VOLUME_VALUE = 0.1

const config = process.env.NODE_ENV === 'production' ? prod : dev

const assets = {
  logo: {
    landscape: LogoL,
    portrait: LogoL,
  },
  banner: {
    landscape: BannerL,
    portrait: BannerP,
  },
  countdown: {
    landscape: CountdownL,
    portrait: CountdownP,
  },
  entrance: {
    landscape: Entrance,
    portrait: EntranceM,
  },
  lobby: {
    landscape: LobbyL,
    portrait: EntranceM,
  },
  auditorium1: {
    landscape: Auditorium,
    portrait: AuditoriumM,
  },

  exhibition1: Exhibition,
  exhibition2: Exhibition,

  networking: {
    landscape: NetworkingL,
    portrait: NetworkingL,
  },
  gallerium: Gallerium,

  lobbyMusic: LobbyMusic,
}

const layout = {
  auditoriumVid: `
    top: 8%;
    bottom: 10.5%;
    left: 4%;
    right: 4.5%;
  `,
}

const lobbyEntries = [
  {
    id: 'auditorium',
    destination: [
      { name: 'English PPT', url: routes.auditorium1 },
      { name: 'Mandarin PPT', url: routes.auditorium2 },
    ],
    css: `
      top: 80.2%;
      left: 23%;
      right: 72%;
      bottom: 20.3%;
    `,
  },
  {
    id: 'helpdesk',
    destination: 0,
    css: `
      top: 74.5%;
      left: 11%;
      right: 86%;
      bottom: 26.5%;
    `,
  },
]

export default {
  API_KEYS,
  assets,
  layout,
  lobbyEntries,
  eventDays,
  VOLUME_VALUE,

  eventName: 'Panashop Virtual Meeting 2021',
  // googleAnalyticsID: 'UA-185128388-1',

  ...config,
}
