export default {
  root: '/',
  forgotPassword: '/registration/forgot-password',
  login: '/login',

  notifications: '/notifications',
  menu: '/menu',

  lobby: '/lobby',

  networking: '/networking-lounge',
  cloudflix: '/highlights',

  auditorium1: '/auditorium-hall-1',
  auditorium2: '/auditorium-hall-2',

  exhibition1: '/exhibition-hall-1',

  gallerium: '/gallerium',
}
