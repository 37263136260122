import React, { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import Media from 'react-media'

import config from './config'
import routes from './routes'
import { SIZES } from './styles/theme'
import ModalContainer from './components/GlobalModals/ModalContainer'
import EntrancePages from './containers/EntrancePages'
import PrivateRoute from './containers/PrivateRoute'

import CountdownPage from './pages/CountdownPage'
import ActiveUserCountPage from './pages/ActiveUserCountPage'

import LobbyPage from './webOnlyPages/LobbyPage'
import AuditoriumPage from './webOnlyPages/AuditoriumPage'

import MobileEntrance from './mobileOnlyPages/MobileEntrance/index.js'
import MobileKFair20 from './mobileOnlyPages/MobileKFair20/index.js'
import MobileLobby from './mobileOnlyPages/MobileLobby/index.js'
import MobileMenu from './mobileOnlyPages/MobileMenu/index.js'
import MobileNotifications from './mobileOnlyPages/MobileNotifications/index.js'

function App() {
  useEffect(() => {
    document.title = config.eventName
  }, [])

  // useEffect(() => {
  //   window.chaport.q('hideLauncher')
  // }, [])

  return useMemo(() => {
    return (
      <>
        <Switch>
          <Route exact path={routes.root} component={CountdownPage} />
          <Route exact path="/realtime" component={ActiveUserCountPage} />
        </Switch>
        <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
          {(matches) =>
            matches ? (
              <Switch>
                <Route exact path={routes.forgotPassword} component={MobileEntrance} />
                <Route exact path={routes.login} component={MobileEntrance} />
                <PrivateRoute mobileNav path={routes.lobby} component={MobileLobby} />

                {/* Auditorium */}
                <PrivateRoute
                  mobileNav
                  path={routes.auditorium1}
                  component={(props) => (
                    <MobileKFair20
                      {...props}
                      hallNumber={1}
                      liveUrl="https://vimeo.com/event/826517/embed?autoplay=1"
                    />
                  )}
                />

                <PrivateRoute
                  mobileNav
                  path={routes.auditorium2}
                  component={(props) => (
                    <MobileKFair20
                      {...props}
                      hallNumber={2}
                      liveUrl="https://vimeo.com/event/826520/embed?autoplay=1"
                    />
                  )}
                />

                <PrivateRoute mobileNav path="/menu" component={MobileMenu} />
                <PrivateRoute mobileNav path={routes.notifications} component={MobileNotifications} />

              </Switch>
            ) : (
              <Switch>
                
                <Route exact path={routes.login} component={EntrancePages} />
                <PrivateRoute fixedNav path={routes.lobby} component={LobbyPage} />

                {/* Auditorium */}
                <PrivateRoute
                  fixedNav
                  path={routes.auditorium1}
                  component={(props) => (
                    <AuditoriumPage
                      {...props}
                      hallNumber={1}
                      hallBg={config.assets.auditorium1.landscape}
                      liveUrl="https://vimeo.com/event/826517/embed?autoplay=1"
                    />
                  )}
                />

                <PrivateRoute
                  fixedNav
                  path={routes.auditorium2}
                  component={(props) => (
                    <AuditoriumPage
                      {...props}
                      hallNumber={2}
                      hallBg={config.assets.auditorium1.landscape}
                      liveUrl="https://vimeo.com/event/826520/embed?autoplay=1"
                    />
                  )}
                />
              </Switch>
            )
          }
        </Media>
      </>
    )
  }, [])
}

export default App
