import React, { useState } from 'react'
import styled from 'styled-components'
import GoogleSheetForm from './GoogleSheetForm'
import ChatCard from './Networking/ChatCard'
import { COLORS } from '../styles/theme'
import useChat from '../utils/chatHelper'

const ChatContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  color: #fff;
  padding: 32px 16px;

  .divider {
    background: #2e3188;
    height: 2px;
  }
  h4 {
    text-align: center;
    margin: 16px;
  }
  .tabs {
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    padding: 8px;

    .active {
      svg path {
        fill: #2e3188;
      }
      small {
        color: #2e3188;
        font-style: italic;
      }
    }
    button {
      background: transparent;
      border: none;
      svg path {
        fill: #1a1a1a;
      }
      small {
        padding: 0 4px;
        font-weight: 600;
        color: #1a1a1a;
      }
    }
  }
  .toggle-content {
    margin: 16px 0;
    color: black;
    height: 100%;
    .ques-container {
      height: 100%;
      .ques-text {
        height: 60%;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 8px;
        margin-bottom: 16px;
        font-size: 14px;
      }
    }
  }
  form {
    width: 100%;
    max-height: 100px;
    text-align: right;
    textarea {
      min-height: 45px;
      resize: none;
      outline: none;
      border: 3px solid ${COLORS.primary};
      background: white;
      border-radius: 8px;
      padding: 8px;
      width: 100%;
      &::placeholder {
        font-size: 12px;
        font-style: italic;
      }
    }

    .btn {
      font-size: 12px;
      font-weight: 600;
      color: ${COLORS.primary};
      background: transparent;
      cursor: pointer;
      svg {
        margin: 0 4px;
      }
    }
  }
  #refresh-btn {
    border: none;
    background: white;
    padding: 5px 10px;

    &:hover {
      border: solid 1px ${COLORS.primary};
    }
  }
`

export default function ChatSection({ hallName, currentUser, firebaseDb }) {
  const {
    messages,
    messagesLoading,
    messagesError,
    handleSubmitMessage,
    submitLoading,
    handleFetchOlderMessages,
    fetchLoading,
    scrollTrigger
  } = useChat(firebaseDb);

  const [showChat, setShowChat] = useState(true)

  const refreshPage = () => {
    window.location.reload()
  }

  const toggleAvatar = () => {
    console.log('Your can find this user in Networking Lounge.')
  }

  return (
    <ChatContainer>
      <div className="divider"></div>
      <div className="tabs">
        {/* <div
          className={`${!showChat && 'active'}`}
          onClick={() => setShowChat(false)}
        >
          <button>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <polygon points="0 0 24 0 24 24 0 24"></polygon>
              <path
                fill="#492777"
                d="M20,2 L4,2 C2.9,2 2,2.9 2,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M19,16 L6,16 L4,18 L4,5 C4,4.45 4.45,4 5,4 L19,4 C19.55,4 20,4.45 20,5 L20,15 C20,15.55 19.55,16 19,16 Z"
              ></path>
            </svg>
            <small>Ask Question</small>
          </button>
        </div> */}
        <div
          className={`${showChat && 'active'}`}
          onClick={() => setShowChat(true)}
        >
          <button>
            <svg width="25" height="25" viewBox="0 0 48 48">
              <path
                fill="#3E2A47"
                d="M23.896,31.209c2.59-1.72,4.302-4.659,4.302-7.994c0-5.29-4.303-9.593-9.593-9.593
                c-5.29,0-9.593,4.303-9.593,9.593c0,3.335,1.712,6.275,4.302,7.994c-5.86,2.162-10.057,7.787-10.057,14.389h2.558
                c0-7.053,5.737-12.79,12.79-12.79c7.053,0,12.79,5.737,12.79,12.79h2.558C33.953,38.996,29.756,33.371,23.896,31.209z
                M18.605,30.249c-3.879,0-7.035-3.156-7.035-7.035s3.156-7.035,7.035-7.035s7.035,3.156,7.035,7.035S22.484,30.249,18.605,30.249z"
              />
              <path
                fill="#3E2A47"
                d="M36.686,24.814c2.59-1.72,4.302-4.659,4.302-7.994c0-5.29-4.303-9.593-9.593-9.593
                c-2.563,0-4.888,1.016-6.61,2.659c-0.638,0.608-1.189,1.304-1.641,2.066c0.798,0.323,1.557,0.721,2.261,1.198
                c0.452-0.736,1.034-1.381,1.717-1.905c1.186-0.912,2.665-1.46,4.274-1.46c3.879,0,7.035,3.156,7.035,7.035
                c0,3.22-2.177,5.935-5.135,6.767c-0.605,0.17-1.241,0.268-1.9,0.268c-0.226,0-0.449-0.013-0.67-0.034
                c-0.046,0.921-0.19,1.814-0.431,2.671c0.366-0.032,0.727-0.079,1.101-0.079c0.519,0,1.029,0.04,1.532,0.101
                c6.332,0.761,11.258,6.156,11.258,12.689h2.558C46.743,32.601,42.546,26.976,36.686,24.814z"
              />
            </svg>
            <small>Group Chat</small>
          </button>
        </div>
      </div>
      <div className="divider"></div>
      <div className="toggle-content">
        {showChat ? 
            messagesError ? (
            <button id="refresh-btn" onClick={refreshPage}>
              Connection failed. Click here to refresh.
            </button>
          ) : (
            <ChatCard
              toggleAvatar={toggleAvatar}
              messages={messages}
              messagesLoading={messagesLoading}
              handleSubmitMessage={handleSubmitMessage}
              submitLoading={submitLoading}
              handleFetchOlderMessages={handleFetchOlderMessages}
              fetchLoading={fetchLoading}
              scrollTrigger={scrollTrigger}
              currentUser={currentUser}
            />
          )
        : (
          <div className="ques-container">
            <div className="ques-text p-3">
              Questions will be sent directly to the organiser.
            </div>
            <GoogleSheetForm hallName={hallName} />
          </div>
        )}
      </div>
    </ChatContainer>
  )
}
