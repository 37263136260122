import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';


import routes from '../../routes';
import config from '../../config';

import MobileLanding from './MobileLanding';
import MobileLogin from './MobileLogin';
import MobileForgotPassword from './MobileForgotPassword';
import MobileResetPassword from './MobileResetPassword';
import logo1 from '../../images/backdrops/PanasonicLogo.png'
import logo2 from '../../images/backdrops/panashop-logo.png'

const MobileEntrance = () => {
  return (
    <Page>
      <div id="content">
        <div className="logo-container">
          <img className="left" src={logo1} alt="logo"/>
          <img className="right" src={logo2} alt="logo"/>
        </div>
        
        <Switch>
          <Route exact path={routes.root}>
            <MobileLanding />
          </Route>

          <Route exact path={routes.login}>
            <MobileLogin />
          </Route>

          <Route exact path={routes.forgotPassword}>
            <MobileForgotPassword />
          </Route>

          <Route exact path={routes.resetPassword}>
            <MobileResetPassword />
          </Route>
        </Switch>
      </div>
    </Page>
  );
}

const Page = styled.div`
  background: url(${config.assets.entrance.portrait});
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;

  #content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .logo-container {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      .left {
        height: 20vh;
      }
      .right {
        margin-top: 0.75rem;
        margin-right: 0.75rem;
        height: 3vh;
      }
    }
  }
  @media screen and (min-width: 540px) {
    #content .logo-container .right {
      margin-top: 1.25rem;
      margin-right: 1.25rem;
      height: 3vh;
    }
  }
`

export default MobileEntrance;