import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAYv9AWdQqQ1Sj3pMqm1gqo64FKcw4JvYU',
  authDomain: 'panashop2021.firebaseapp.com',
  projectId: 'panashop2021',
  storageBucket: 'panashop2021.appspot.com',
  messagingSenderId: '139934708088',
  appId: '1:139934708088:web:f59749275d028b8203d88d',
}

firebase.initializeApp(firebaseConfig)

const firebaseDb = firebase.database()
const firebaseAuth = firebase.auth()

const ChatDb = firebaseDb.ref('chat')
const ChatKfairDb = firebaseDb.ref('chatAuditorium')
const ChatMcDb = firebaseDb.ref('chatMc')
const NoticeDb = firebaseDb.ref('notice')
const HallDb = firebaseDb.ref('hall')
const AttendanceDb = firebaseDb.ref('attendance')
const NotificationDb = firebaseDb.ref('pushNotifications')

export {
  firebaseDb,
  firebaseAuth,
  ChatDb,
  ChatKfairDb,
  ChatMcDb,
  NoticeDb,
  NotificationDb,
  HallDb,
  AttendanceDb,
}
