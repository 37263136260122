import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Amplitude from '../../utils/amplitudeHelper'
import routes from '../../routes'
import { toggleMute } from '../../ducks/layout'
import MobileNotice from './MobileNotice'
import logo1 from '../../images/backdrops/panasonic-logo.png'
import logo2 from '../../images/backdrops/panashop-logo.png'
import meeting from '../../images/mobile/meeting.png'
import mute from '../../images/mobile/mute.png'
import config from '../../config'
import { logoutUser } from '../../ducks/auth'

const MobileLobby = ({ currentUser }) => {
  const dispatch = useDispatch()
  const { muted } = useSelector((state) => state.layout)

  useEffect(() => {
    document.title = `${config.eventName} | Lobby`
  }, [])

  const toggleAudio = () => {
    Amplitude.clickMute()
    dispatch(toggleMute())
  }

  const handleLogout = () => {
    Amplitude.clickLogout()
    dispatch(logoutUser())
  }
  
  useEffect(() => {
    Amplitude.visit('Lobby')
  }, [currentUser.id])

  return (
    <Page>
      <div className="logo-container">
        <img className="left" src={logo1} height="30px" alt="logo"/>
        <img className="right" src={logo2} height="35px" alt="logo"/>
      </div>
      <img id="portrait" className="banner" src={config.assets.banner.portrait} width="90%" alt="event banner"/>
      <MobileNotice />
      <div className="menu">
        <p className="title">Virtual Halls</p>
        <div className="btn-container">
          <div className="mute-button" onClick={toggleAudio}>
            <img className="mute-icon" src={mute} />
            <p className="mute-label">{muted ? 'Unmute' : 'Mute'}</p>
          </div>
          <div className="logout-button" onClick={handleLogout}>
            <svg className="logout-icon" width="30.797" height="29.609" viewBox="0 0 30.797 29.609">
              <g transform="translate(0 0)">
                <g>
                  <g>
                    <path d="M311.743,156.064c.012-.014.022-.029.033-.044s.027-.035.039-.053.022-.036.033-.054.02-.033.029-.05.018-.037.027-.056.017-.035.025-.053.014-.037.02-.055.014-.039.021-.059.01-.037.014-.056.011-.04.015-.061.007-.044.01-.065.006-.036.008-.054a1.253,1.253,0,0,0,0-.243c0-.018-.005-.036-.008-.054s-.006-.044-.01-.065-.01-.041-.015-.061-.009-.038-.014-.056-.013-.039-.02-.059-.013-.037-.02-.055-.016-.036-.025-.053-.017-.038-.027-.056-.02-.033-.029-.05-.021-.036-.033-.054-.026-.035-.039-.053-.021-.03-.033-.044c-.026-.031-.053-.061-.081-.09h0l-3.7-3.7a1.234,1.234,0,1,0-1.745,1.745l1.6,1.6H300.92a1.234,1.234,0,1,0,0,2.467h6.891l-1.6,1.6a1.234,1.234,0,0,0,1.745,1.745l3.7-3.7h0Q311.7,156.111,311.743,156.064Z" transform="translate(-281.225 -141.712)" fill="#002060"/>
                    <path d="M21.984,18.277a1.234,1.234,0,0,0-1.234,1.234v6.168H15.816V5.941h0a1.234,1.234,0,0,0-.879-1.182L10.651,3.473h10.1V9.642a1.234,1.234,0,0,0,2.467,0V2.24a1.234,1.234,0,0,0-1.234-1.234H2.245c-.036,0-.073,0-.108,0a1.182,1.182,0,0,0-1.069.859c-.009.028-.016.056-.023.084l0,.009q-.01.044-.017.089l0,.013c0,.028-.007.056-.009.084a1.32,1.32,0,0,0,0,.137V26.914A1.234,1.234,0,0,0,2,28.123L14.34,30.591a1.234,1.234,0,0,0,1.476-1.21V28.147h6.168a1.234,1.234,0,0,0,1.234-1.234v-7.4A1.234,1.234,0,0,0,21.984,18.277Zm-8.636,9.6L3.479,25.9V3.9l9.869,2.961V27.876Z" transform="translate(-1.011 -1.006)" fill="#002060"/>
                  </g>
                </g>
              </g>
            </svg>
            <p className="logout-label">Logout</p>
          </div>
        </div>
        <div className="list-wrapper">
          <div className="item-box">
            <div className="icon-wrapper">
              <img className="meeting" src={meeting} alt="icon" />
            </div>
            <p className="item-label">Conference Hall English PPT</p>
            <Link to={routes.auditorium1} className="item-button">
              Enter
            </Link>
          </div>

          <div className="item-box">
            <div className="icon-wrapper">
              <img className="meeting" src={meeting} alt="icon" />
            </div>
            <p className="item-label">Conference Hall Mandarin PPT</p>
            <Link to={routes.auditorium2} className="item-button">
              Enter
            </Link>
          </div>

        </div>
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: url(${config.assets.countdown.portrait});
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  height: calc(100% - 60px);
  width: 100vw;
  overflow: hidden;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .logo-container {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    .left, .right {
      height: 2.2vh;
      margin: 1em;
      padding: 0;
    }
    .left {
      height: 1.9vh;
    }
  }
  .menu {
    width: 100%;
    height: 220px;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    background: white;
    border-radius: 20px 20px 0px 0px;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    position: absolute;
    top: 15px;
    left: 15px;
  }

  img#portrait {
    display: block;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -35%);
    width: 58%;
  }
  .btn-container {
    display: flex;
    position: absolute;
    top: 17px;
    right: 20px;
  }
  .mute-button, .logout-button {
    width: 80px;
    height: 22px;
    display: flex;
    align-items: center;
  }
  .mute-icon {
    height: 15px;
    margin-right: 3px;
  }
  .logout-icon {
    height: 15px;
  }

  .mute-label, .logout-label{
    font-size: 12px;
    margin: 0;
  }
  .logout-label {
    color: #002060;
  }

  .list-wrapper {
    width: 100%;
    height: 160px;
    position: absolute;
    top: 50px;
    left: 0;
    overflow-x: scroll;
    display: flex;
    align-items: center;
  }

  .item-box {
    width: 130px;
    height: 160px;
    margin-left: 20px;
    border: solid 3px #FFB100;
    border-radius: 10px;
    background: white;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .icon-wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-label {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    color: #272727;
    margin: 0px;
  }

  .item-button {
    background-color: #270949;
    color: white;
    border: none;
    border-radius: 30px;
    font-size: 12px;
    font-weight: bold;
    width: 90px;
    height: 25px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .meeting1 {
    width: 135%;
    transform: translateY(15%);
  }

  .meeting2 {
    width: 85%;
  }

  .meeting {
    width: 150%;
  }

  .expo {
    width: 170%;
  }

  .gallerium {
    width: 140%;
  }

  .networking {
    width: 110%;
  }

  @media screen and (min-width: 540px) {
    .video-wrapper {
      width: 500px;
      height: 281.25px;
      padding-bottom: 0;

      position: absolute;
      /*top: 30%;*/
      bottom: 230px;
      left: 50%;
      transform: translateX(-50%);
    }
    .menu {
      height: 275px;
    }
    .list-wrapper {
      top: 65px;
    }
    .logo {
      width: 50%;
      margin: 100px auto;
    }

  }
`

export default MobileLobby
