import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import config from '../config'
import { SIZES } from '../styles/theme'
import logo1 from '../images/backdrops/panasonic-logo.png'
import logo2 from '../images/backdrops/panashop-logo.png'
import EntrancePages from '../containers/EntrancePages'
import MobileEntrance from '../mobileOnlyPages/MobileEntrance'
import Media from 'react-media'

const Page = styled.div`
  min-height: 100vh;
  width: 100vw;
  text-align: center;

  #content {
    position: absolute;
    top: 0;
    width:100%;
  }

  #header {
    padding-top: 3vh;
    background-color: #2e3188;
    background-size: cover;

    h1 {
      color: white;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 0.8em;
      font-size: 2rem;
    }

    .time-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      text-transform: uppercase;

      .time-card {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(242, 241, 241, 1) 70%,
          rgba(180, 179, 179, 1) 100%
        );

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        min-width: 120px;
        width: 10vw;
        margin: 0 1%;
        margin-top: 5px;
        padding-top: 10px;

        p {
          margin-bottom: 6px;
          font-weight: 500;
        }

        h2 {
          font-weight: 700;
          font-size: 3.2rem;
        }
      }
    }
    ${SIZES.mobile} {
      padding-top: 6vh;
      h1 {
        margin-bottom: 1.5em;
      }
    }
  }

  #bg {
    background-image: url(${config.assets.countdown.landscape});
    background-size: cover;
    padding: 32px 16px;
    height: 100%;
    min-height: calc(100vh - 200px);
    img#landscape {
      display: block;
      margin: 40px auto;
    }
    img#portrait {
      display: none;
    }
    .logo-container {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
    }
    ${SIZES.mobile} {
      background-image: url(${config.assets.countdown.portrait});
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      height: calc(100vh - 250px);
      min-height: 100%;
      position: relative;
      padding: 0;
      .logo-container {
        .left, .right {
          height: 2.2vh;
          margin: 1em;
          padding: 0;
        }
      }
      img#landscape {
        display: none;
      }
      img#portrait {
        display: block;
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -35%);
        width: 58%;
      }
    }
  }

`

export default function CountdownPage() {
  const calculateTimeLeft = () => {
    // year, actual month - 1, day, hour, minute, second, ignore
    // 29 Mar 2021 3pm (2:40 open)
    const difference = +new Date(2021, 2, 29, 14, 40, 0, 0) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    localStorage.clear()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval) => {
    if (isNaN(timeLeft[interval])) {
      return
    }

    timerComponents.push(
      <div className="time-card">
        <p>{interval}</p>
        <h2>{timeLeft[interval]}</h2>
      </div>
    )
  })
  if (!timerComponents.length) {
    return (
      <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
      {(matches) =>
        matches ?   
          <MobileEntrance />
        : 
          <EntrancePages />
      }
      </Media>
    )
  }
  return (
    <Page>
      <div id="content">
        <div id="header">
          <h1>Panashop Virtual Meeting 2021</h1>
          <div className="time-wrapper">
            {timerComponents.length ? timerComponents : <span>Time's up!</span>}
          </div>
        </div>
        <div id="bg">
          <div className="logo-container">
            <img className="left" src={logo1} height="30px" alt="logo"/>
            <img className="right" src={logo2} height="35px" alt="logo"/>
          </div>
          <img id="landscape" className="banner" src={config.assets.banner.landscape} width="80%" alt="event banner"/>
          {/* <img id="portrait" className="banner" src={preview} width="100%" alt="event banner"/> */}
          <img id="portrait" className="banner" src={config.assets.banner.portrait} width="90%" alt="event banner"/>
        </div>
      </div>
    </Page>
  )
}
